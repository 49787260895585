<template>
  <SharedMembershipBox
    class="border-yellow-600 bg-yellow-50"
    :price="price"
    title="haelsi Rundum"
    :link="link"
    :type="type"
    :highlight="true">
    <MembershipBaseList class="text-sm text-gray-600">
      <AppListItem type="checkmark">
        <AppLink to="/vorsorge"> <span class="font-medium">Premium Vorsorgecheck:</span></AppLink> Dein umfassender
        Gesundheitscheck, jährlich inkludiert
      </AppListItem>
      <AppListItem type="checkmark">
        <span class="font-medium">Mitglieder-Support:</span> Priorität bei Terminen, via Telefon &amp; E-Mail
      </AppListItem>
      <AppListItem type="checkmark">
        <span class="font-medium">15&nbsp;&#037; Rabatt auf Mikronährstoffe</span>
      </AppListItem>
    </MembershipBaseList>
  </SharedMembershipBox>
</template>

<script setup lang="ts">
import { Membership } from "~/utils/enum/Membership";
import { createMembershipLink, getMembershipPrice } from "~/utils/membership";

const props = withDefaults(
  defineProps<{
    type?: "monthly" | "yearly";
    quickCheckout?: boolean;
  }>(),
  {
    quickCheckout: true,
    type: "yearly",
  },
);

const price = getMembershipPrice(Membership.RUNDUM, props.type);
const link = createMembershipLink(Membership.RUNDUM, props.type, "quick_checkout");
</script>

<style scoped></style>
