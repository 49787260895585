<template>
  <SharedMembershipBox
    class="border-lavender-600 bg-lavender-100"
    :price="price"
    title="haelsi Plus"
    :link="link"
    :type="type">
    <MembershipBaseList class="text-sm text-gray-600">
      <AppListItem type="checkmark">
        <span class="font-medium">30&nbsp;&#037; Rabatt</span> auf alle Honorare bei haelsi Therapeut:innen
      </AppListItem>
    </MembershipBaseList>
  </SharedMembershipBox>
</template>

<script setup lang="ts">
import { Membership } from "~/utils/enum/Membership";
import { createMembershipLink, getMembershipPrice } from "~/utils/membership";

const props = withDefaults(
  defineProps<{
    type?: "monthly" | "yearly";
    quickCheckout?: boolean;
  }>(),
  {
    quickCheckout: true,
    type: "yearly",
  },
);

const price = getMembershipPrice(Membership.PLUS, props.type);
const link = createMembershipLink(Membership.PLUS, props.type, "quick_checkout");
</script>

<style scoped></style>
